import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d35dac1c = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _cf7ab9d6 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _738a97b7 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _687376c4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8fc3f292 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _4295c902 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _51ba3b26 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _95478b6e = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _c74156fc = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _0abfcad6 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _27ce7c78 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _b9c740a6 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _7cd1ecce = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _64f0930d = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _0c5b8e99 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _183eced6 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _25964888 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _3f33c5fb = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _788323c4 = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _12f73810 = () => interopDefault(import('../pages/top-locations/liget-budapest-visitor-center.vue' /* webpackChunkName: "pages/top-locations/liget-budapest-visitor-center" */))
const _4afbba28 = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _bdb7e316 = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _0c1f9714 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _6fe0d459 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _63390133 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _7143afd1 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _b26f4b0e = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _b7cb8c7e = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _7fe4ae7b = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _5ef43d7a = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _afd4433c = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _529b80ec = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _7e015fb5 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _55860680 = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _ef43f278 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _2ce1a092 = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _76584b9d = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _daf5d352 = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _213e92ea = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _5aa292ad = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _66bcd57c = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _d35dac1c,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _cf7ab9d6,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _738a97b7,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _687376c4,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _8fc3f292,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _4295c902,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _51ba3b26,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _95478b6e,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _c74156fc,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _0abfcad6,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _27ce7c78,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _b9c740a6,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _7cd1ecce,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _64f0930d,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _0c5b8e99,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _738a97b7,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _64f0930d,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _4295c902,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _8fc3f292,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _95478b6e,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _0abfcad6,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _7cd1ecce,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _27ce7c78,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _cf7ab9d6,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _0c5b8e99,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _51ba3b26,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _b9c740a6,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _d35dac1c,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _c74156fc,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _183eced6,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _25964888,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _3f33c5fb,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _788323c4,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/liget-budapest-latogatokozpont",
    component: _12f73810,
    name: "top-locations-liget-budapest-visitor-center___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _4afbba28,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _bdb7e316,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _0c1f9714,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _6fe0d459,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _63390133,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _7143afd1,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _b26f4b0e,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _b7cb8c7e,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _3f33c5fb,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _183eced6,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _25964888,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _788323c4,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _4afbba28,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _bdb7e316,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _0c1f9714,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/liget-budapest-visitor-center",
    component: _12f73810,
    name: "top-locations-liget-budapest-visitor-center___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _6fe0d459,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _63390133,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _7143afd1,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _b26f4b0e,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _b7cb8c7e,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _7fe4ae7b,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _5ef43d7a,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _afd4433c,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _529b80ec,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _7e015fb5,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _55860680,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _ef43f278,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _2ce1a092,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _76584b9d,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _daf5d352,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _213e92ea,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _5aa292ad,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _7fe4ae7b,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _5ef43d7a,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _afd4433c,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _66bcd57c,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _7e015fb5,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _55860680,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _5aa292ad,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _ef43f278,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _daf5d352,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _76584b9d,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _213e92ea,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _2ce1a092,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _529b80ec,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _687376c4,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _66bcd57c,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
