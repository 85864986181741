export const state = () => ({
    metaData: [],
    publicAnnouncements: [],
    recommendedEvents: [],
    newsCategories: [],
    eventCategories: [],
    eventTypesByCategory: [],
    eventLocations: [],
    staticImages: {},
    menuZones: [],
    balloonStatus: null,
    homepageHighlightedNews: [],
    dismissedPublicAnnouncementIDs: [],
    redirects: [],
    qrCodeRedirects: [],
});

export const getters = {
    getPublicAnnouncementsToDisplay(state) {
        // "cannot read publicAnnouncements of undefined" error happened twice for unknown reasons,
        // make sure it does not do that
        if (!state || !state.publicAnnouncements) {
            return [];
        }

        return state.publicAnnouncements.filter((announcement) => {
            return !state.dismissedPublicAnnouncementIDs.find((announcementID) => announcementID === announcement.id);
        });
    },
    getEventLocationOptions(state) {
        return state.eventLocations.map((location) => {
            return {
                id: location.id,
                label: location.title,
                value: location.slug,
            };
        });
    },
    getCulturalEventTypeOptions(state) {
        return state.eventTypesByCategory.reduce((acc, curr) => {
            // curr is event category
            if (curr.id === 1) {
                curr.event_types.forEach((eventType) => {
                    acc.push({
                        id: eventType.id,
                        label: eventType.name,
                        value: eventType.slug,
                    });
                });
            }
            return acc;
        }, []);
    },
    getMenuZones(state) {
        return state.menuZones.reduce((acc, curr) => {
            acc[curr.name] = {
                menuItems: curr.menus.filter((x) => x.title && x.url),
            };
            return acc;
        }, {});
    },
};

export const mutations = {
    setMetaData(state, payload) {
        state.metaData = payload.data;
    },
    setPublicAnnouncements(state, payload) {
        state.publicAnnouncements = payload.data;
    },
    setRecommendedEvents(state, payload) {
        state.recommendedEvents = payload.data;
    },
    setEventCategories(state, payload) {
        state.eventCategories = payload.data;
    },
    setEventTypesByCategory(state, payload) {
        state.eventTypesByCategory = payload.data;
    },
    setEventLocations(state, payload) {
        state.eventLocations = payload.data;
    },
    setNewsCategories(state, payload) {
        state.newsCategories = payload.data;
    },
    setMenuZones(state, payload) {
        state.menuZones = payload.data;
    },
    setBalloonStatus(state, payload) {
        state.balloonStatus = payload.data;
    },
    setHomepageHighlightedNews(state, payload) {
        state.homepageHighlightedNews = payload.data;
    },
    setRedirects(state, payload) {
        state.redirects = payload.data;
    },
    setQrCodeRedirects(state, payload) {
        state.qrCodeRedirects = payload.data;
    },
    setStaticImages(state, payload) {
        state.staticImages = payload.data.reduce((acc, curr) => {
            curr.srcSet = curr.media.static_image;
            delete curr.media;
            acc[curr.key] = curr;
            return acc;
        }, {});
    },
    setPublicAnnouncementToDismissed(state, announcementID) {
        state.dismissedPublicAnnouncementIDs.push(announcementID);
    },
};
